<template>
  <div class='userInfo-mobile'>
    <!-- NavBar 导航栏 -->
    <div class="NavBar flex-x-between-centerHor ">
      <div class="sidebar" @click="navsList = !navsList">
        <i class="iconfont icon-icon_caidan"></i>
      </div>
      <div class="top">个人中心</div>
      <div class="sidebar">
        <i class="none iconfont icon-icon_caidan"></i>
      </div>
    </div>
    <div class="scroll">
      <!-- <div class="header">555</div> -->
      <!-- 填写基本信息 模块 -->
      <div class="mods title">填写基本信息</div>
      <div class="mods cards">
        <div class="card flex-x-left-centerHor">
          <div class="label">头像
            <div class="ps">请上传高清图</div>
          </div>
          <!-- <div v-if="dataLoad" class="skeleton-item"></div> -->
          <div class="input avatar">
            <div class="demo-image__preview">
              <div class="imageDefault" v-if="!userAvatar">{{ username.substring(0, 2) }}</div>
              <el-image class="image" v-if="userAvatar" :src="userAvatar" :preview-src-list="srcList">
              </el-image>
            </div>
            <el-upload list-type="picture-card" :action="uploadUrl + '/update-avatar'" :show-file-list="false"
              :headers="token" :on-preview="handlePictureCardPreview" :on-success="handleAvatarSuccess"
              :on-error="handleAvatarError" :before-upload="beforeAvatarUpload" :accept="acceptTypes">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <div class="card flex-x-left-centerHor">
          <div class="label">昵称</div>
          <div class="input flex-x-left-centerHor">
            <input type="text" class="content_label_input" v-model="username" placeholder="需在20个字符以内">
            <button class="content_label_btn" @click="save()">保存</button>
          </div>
        </div>
        <div class="card flex-x-left-centerHor">
          <div class="label">电子邮箱</div>
          <div class="input flex-x-left-centerHor">
            <input type="text" class="content_label_input" v-model="userEmail" placeholder="绑定或修改邮箱">
            <button class="content_label_btn" @click="bindEmail()">{{ emailStatus ? '修改' : '绑定' }}</button>
          </div>
          <div class="btn"></div>
        </div>
        <div class="card flex-x-left-centerHor">
          <div class="label">手机</div>
          <div class="input flex-x-left-centerHor">
            <input type="text" class="content_label_input" v-model="userPhone" placeholder="绑定或修改手机">
            <button class="content_label_btn" @click="bindPhone()">{{ phoneStatus ? '修改' : '绑定' }}</button>
          </div>
          <div class="btn"></div>
        </div>
        <div class="card ">
          <div class="flex-x-left-centerHor">
            <div class="label">AI员工
            </div>
            <div class="input flex-x-left-centerHor">
              <input type="text" class="content_label_input " 
                :disabled="bases.length ? true : false" v-model="emp_id" placeholder="AI员工ID">
              <button class="content_label_btn" @click="handleSwitchChange(emp_id, bases.length ? false : true)">
                {{ bases.length ? '解绑' : '绑定' }}</button>
            </div>
          </div>
          <div class="ps">请前往<span class="link" @click="toGPTadmin">奇妙人AI员工管理平台</span>获取员工ID</div>
        </div>
        <div class="card ">
          <div class="flex-x-left-centerHor">
            <div class="label">微信公众号
            </div>
            <div class="input flex-x-left-centerHor">
              <input type="text" class="content_label_input " 
                :disabled="app_id ? true : false" v-model="officialIdValue" placeholder="AppID">
              <button class="content_label_btn " :class="app_id ? 'content_label_btn_default' : ''"
                @click="handleSwitchChangeAppID(officialIdValue, app_id ? false : true)">
                {{ app_id ? '已授权' : '授权' }}</button>
            </div>
          </div>
          <div class="ps">请前往<span class="link" @click="toWeChatSetting">公众号后台获取APPID</span>并扫码授权</div>
        </div>
      </div>
      <!-- 点击图标预览 模块 -->
      <div class="mods title">点击图标预览</div>
      <div class="mods preview"> <img class="logo hoverHead" src="../../../../../src/assets/icon/otherMod.png"
          @click="$router.push('/ai-voice')" /></div>
    </div>
    <!-- 左侧Sidebar -->
    <el-drawer title="我是标题" direction="ltr" :visible.sync="navsList" :with-header="false">
      <links v-if="navsList" :linkList="linkList" :linkListEnd="linkListEnd" @childEvent="handleChildEvent"></links>
    </el-drawer>

  </div>
</template>

<script>
import { watch } from "less";
import { userInfo, updateUserAlias, updateBaseUsage, checkUser, updateAvatar, wechatQrcode, wechatQrcodeCallback } from "../../../../config/api.js"
import { getBaseIp } from "../../../../config/request.js"
import links from "../../../../components/center/links.vue"
// import { mapState } from 'vuex' // 从store中导入【全局】数据
// 定义Vue组件的业务逻辑
export default {
  // 为组件命名
  // name:'',
  // 加载子目录组件👇
  // components: {
  // } ,
  // 如果作为子组件向父组件传递
  props: {
    userData: { // xxx 引导子组件向父组件👆传值的要素,父组件里要有定义
      type: Object,
      required: true
    }
  },
  components: {
    links,
  },
  // 私有数据
  data() {
    return {
      dataLoad: true,
      /**填写基本信息模块 */
      username: "",
      userEmail: " ",
      userPhone: 0,
      emp_id: null,
      userAvatar: "",
      srcList: [this.userAvatar],
      uploadUrl: getBaseIp(), // 上传头像的url
      token: { "Authorization": JSON.parse(localStorage.getItem("userData")).token },
      imageUrl: "",
      acceptTypes: 'image/*', // 允许上传图片文件
      /**AI 员工有无权限 */
      // asst_enable: false,// AI员工权限
      bases: [],
      app_id: "", // 已经绑定的微信公众号的值
      officialIdValue: "", // 微信公众号输入框的值
      emailStatus: true,//邮箱输入框状态
      phoneStatus: true,//手机输入框状态
      /**左侧Sidebar */
      navsList: false, // 导航栏默认隐藏
      balance: null,
      linkList: [
        { icon: "icon-userinfo1", name: this.$t("个人中心"), path: "userInfo" },
        { icon: "icon-recharge", name: this.$t("充值记录"), path: "payForm" },
        { icon: "icon-shiyongjilu",name: this.$t("使用记录"),path: "useForm"}
      ],
      linkListEnd: [
        { icon: "icon-yue",name: '剩余用量：' ,path: ""},
        { icon: "icon-logout", name: this.$t("退出登录"), path: "/" },
      ]

    }
  },
  // 创建生命周期函数,立即获取所有元素
  // 当页面刚加载的时候会执行的钩子函数
  created() {
    // this.getuserInfo();
  },
  mounted(){
    this.getuserInfo();
  },
  // 处理函数
  methods: {
    getuserInfo() {
      userInfo().then(res => {
        this.dataLoad = false;
        this.username = res.data.nickname || res.data.username;
        this.userEmail = res.data.email;
        this.emailStatus = !(!(res.data.email))
        this.userPhone = res.data.phone;
        this.phoneStatus = !(!(res.data.phone))
        this.userAvatar = res.data.avatar;
        this.bases = res.data.bases;
        this.officialIdValue = res.data.app_id
        this.app_id = res.data.app_id
        if (res.data.bases.length) {
          this.emp_id = res.data.bases[0].emp_id
        }
        this.linkListEnd[0].name = '剩余用量：' + res.data.balance
      });
    },
    handleChildEvent(value) {
      this.navsList = value
    },
    save() {
      let params = {
        alias: this.username
      };
      if (!params.alias) return this.$message({ showClose: true, message: this.$t('姓名是必填字段'), type: "error" });
      updateUserAlias(params)
        .then((res) => {
          if (res.data.status === 'success') {
            this.$message({ showClose: true, message: this.$t('保存成功'), type: "success" });
          }
        })
        .catch(() => { });
    },
    handleAvatarSuccess(res, file, fileList) {
      this.$message({ showClose: true, message: this.$t('头像上传成功'), type: "success" });
      this.getuserInfo();
    },
    handleAvatarError() {
      this.$message({ showClose: true, message: this.$t('头像上传失败'), type: "error" });
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error(this.$t('上传头像图片大小不能超过2MB'));
      }
      return isLt2M;
    },
    uploadFile() {
      const fileInput = this.$refs.fileInput;
      fileInput.click();
      fileInput.removeEventListener('change', this.handleImageUpload); // 移除之前的change事件监听器
      fileInput.addEventListener('change', this.handleImageUpload);
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file && file.type.includes('image/')) {
        if (file.size > 2 * 1024 * 1024) {
          return this.$message.error(this.$t('上传头像图片大小不能超过2MB'));
        }
        if (file.size <= 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append('file', file);

          // 发送文件上传请求
          // 例如使用axios发送POST请求
          updateAvatar(formData)
            .then(res => {
              this.$message({ showClose: true, message: this.$t('头像上传成功'), type: "success" });
              this.getuserInfo();
            })
            .catch(error => {
              this.$message({ showClose: true, message: this.$t('头像上传失败'), type: "error" });
            });
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSwitchChange(emp_id, value) {
      if (!emp_id) return this.$message.error('Assistant ID 不能为空');
      updateBaseUsage({
        "emp_id": emp_id,
        "enable": value
      }).then(res => {
        if (res.data.status === 'success') {
          if (value)
            this.$message.success(this.$t('绑定成功'));
          else
            this.$message.success(this.$t('解绑成功'));
        }
        if (res.data.status === 'error') {
          if (res.data.msg === '该助手未激活')
            return this.$message.error(this.$t('该助手未激活'))
          if (res.data.msg === '请输入正确的AI员工ID')
            return this.$message.error(this.$t('请输入正确的AI员工ID'))
        }
        if (this.bases.length) { this.emp_id = null }
        this.getuserInfo()
      }).catch(err => {
        if (value)
          this.$message.error(this.$t('绑定失败'));
        else
          this.$message.error(this.$t('解绑失败'));
      })
    },
    handleSwitchChangeAppID(officialIdValue, value) {
      if (this.app_id) return;
      if (!officialIdValue && !this.app_id) return this.$message.error('微信公众号不能为空');
      wechatQrcode({
        "app_id": this.app_id ? "" : officialIdValue,
      }).then(res => {
        if (res.data.status === 'success') {
          let link = res.data.data;
          window.location.href = 'https://www.qimiaoren.com/wechat-qrcode/accredit.html?url=' + link; // ← 别试了，不是这样的！你得先这样 ↓ 然后再这样 ↓ 最后再那样 ↓ 
        }
        if (res.data.status === 'error') {
          return this.$message.error(res.data.msg);
        }
        if (!this.app_id) { this.officialIdValue = '' }
        this.getuserInfo()
      }).catch(err => {
        this.$message.error(this.$t('授权失败'));
      })
    },
    toGPTadmin() {
      let link = this.uploadUrl.replace(/\/[^/]*$/, '') + '/gpt-admin/';
      console.log(link)
      window.open(link, "_blank");
    },
    toWeChatSetting() {
      let link = 'https://mp.weixin.qq.com/'
      window.open(link, "_blank");
    },
    bindEmail() {
      if (!this.userEmail) {
        return (this.$message.error(this.$t("邮箱不能为空")));
      }
      else {
        checkUser({
          username: this.userEmail
        }).then(res => {
          /**regMethod为注册格式 -1为不合规 1为邮箱 2为手机  */
          if (res.data["regMethod"] === -1) {
            return this.$message.error(this.$t("填写格式有误"));
          }
          if (res.data["checkOrNot"]) {
            this.$message.error(this.$t("该邮箱已被占用"));
          }
          else {
            let emailPlaceholder = res.data["regMethod"]
            this.$router.push({
              name: 'bindEmail', query: {
                userEmail: this.userEmail,
                emailPlaceholder
              }
            })
          }
        });
      }
    },
    bindPhone() {
      if (!this.userPhone) {
        return (this.$message.error(this.$t("电话号码不能为空")));
      }
      else {
        checkUser({
          username: this.userPhone
        }).then(res => {
          /**regMethod为注册格式 -1为不合规 1为邮箱 2为手机  */
          if (res.data["regMethod"] === -1) {
            return this.$message.error(this.$t("填写格式有误"));
          }
          if (res.data["checkOrNot"]) {
            this.$message.error(this.$t("该号码已被占用"));
          }
          else {
            let emailPlaceholder = res.data["regMethod"]
            this.$router.push({
              name: 'bindPhone', query: {
                userPhone: this.userPhone,
                emailPlaceholder
              }
            })
          }
        });
      }
    }
  },
  watch: {
    userData(newValue) {
      this.dataLoad = false;
      this.username = newValue.nickname || newValue.username;
      this.userEmail = newValue.email;
      this.emailStatus = !(!(newValue.email))
      this.userPhone = newValue.phone;
      this.phoneStatus = !(!(newValue.phone))
      this.userAvatar = newValue.avatar;
      this.bases = newValue.bases;
      this.officialIdValue = newValue.app_id;
      this.app_id = newValue.app_id;
      if (newValue.bases.length) {
        this.emp_id = newValue.bases[0].emp_id;
      };
    },
    userAvatar(newVal) {
      this.srcList = []
      this.srcList.push(newVal)
    },
    balance(newVal){
      this.linkListEnd[0].name = '剩余用量：' + newVal
    }
  },

  // 将【全局】数据,映射为当前组件的计算属性
  computed: {
    // balance() {
    //   return this.$store.state.balance; // 获取余额
    // }
  },

  // ...其他业务逻辑
}
</script>

<style lang='less' scoped>
/**这个语法中支持less scoped只在当前组建生效，没有则全局生效 */
/**定义组件的形式 */
.userInfo-mobile {
  background-color: #f7f8fa;
  width: 100vw;
  height: 100vh;
  font-size: 1.4rem;
  overflow: hidden;

  .NavBar {
    width: 100%;
    height: 7rem;
    line-height: 7rem;
    background: #fff;
    font-size: 2.2rem;
    font-weight: 700;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;

    .iconfont {
      font-size: 1.8rem;
      font-weight: 700;
      padding: 0 1.6rem;
    }

    .none {
      color: #fff;
    }
  }

  .scroll {
    height: calc(100vh - 7rem);
    overflow-y: auto !important;
    position: fixed;
    top: 7rem;
    left: 0;

    .header {
      width: 100%;
      height: 7rem;
      margin: 0 !important;
      color: #fff;
      background-color: #000;
    }

    .title {
      padding: 2.2rem 0;
      color: #8c99a0;
    }

    .mods {
      margin: 0 1.6rem;
    }

    .cards {
      border-radius: 0.6rem;
      background-color: #fff;
      padding: 0 1.5rem;

      .card {
        padding: 1rem 0;
        border-bottom: 1px solid #f3f3f3;
        --label-width: 7.75rem;

        .label {
          width: var(--label-width);
        }

        --right-width:calc(100vw - 6.2rem - var(--label-width));

        .input {
          width: var(--right-width);

          >div {
            display: flex;

            >.user-profile-setting {
              margin: 0;
              margin-left: calc(0.3rem + 1px);
              margin-left: 30.5rem;
              /**微信风格 */
            }
          }

          .continue {
            line-height: 36px;
            height: 36px;
            border: 1px solid #c5c5d2;
            border-radius: 8px;
          }

          .continue-01 {
            margin-left: 0.5rem;
            display: flex;
            margin-bottom: 3rem;
          }

          .iconfonts {
            padding: 10px;
            cursor: pointer;
            font-size: 12px;
            position: absolute;
            top: 50%;
            transform: translate(-100%, -50%);
          }

          .tips {
            display: inline-flex;
            align-items: center;
            font-size: 16px;
            line-height: 36px;
            height: 36px;

            >i {
              margin: 0 3px 0 10px;
            }
          }

          .success {
            color: #10a37f;
          }

          .error {
            color: #d24726;
          }

          i:hover {
            cursor: pointer;
          }

          .content_label_input {
            width: 100%;
            border: 0;
            border-radius: 0.5rem;
            color: #353535;
            /**禁用输入框的默认样式、阴影效果，并将点击高亮效果设置为透明 */
            -webkit-appearance: none;
            box-shadow: none;
            -webkit-tap-highlight-color: transparent;
          }

          .content_label_input:focus {
            outline: none;
            border-radius: 0.5rem;
            background-color: #fff;
            border: 0;
          }

          .content_label_input::placeholder {
            color: #c8c9cc;
            font-size: 1.4rem !important;
          }

          .content_label_input::-webkit-input-placeholder {
            margin-left: 10%;
            text-indent: 20px;
          }

          .content_label_input::placeholder {
            text-indent: 0px;
            /* 调整 placeholder 与左侧的间距 */
          }

          .content_label_input:disabled {
            cursor: not-allowed;
            background-color: #fff;
            color: #3e3e3e;
            opacity: 1; /* 取消淡化效果 */
            pointer-events: none; /* 禁用用户交互 */
          }

          --label_btn_width: 5.65rem;

          .content_label_btn {
            width: var(--label_btn_width);
            min-width: var(--label_btn_width);
            height: calc(var(--label_btn_width) * 0.618 - 0.25rem);
            border-radius: .3rem;
            background-color: #10a37f;
            color: #fff;
            border: 0;
            margin-left: 4rem;
          }
          .content_label_btn_default {
            background-color: #eff0f2;
            color: #858585;
            border-color: #e4e8eb;
          }

          .content_label_btn_default:hover {
            background-color: #eff0f2;
            color: #858585;
            border-color: #e4e8eb;
            cursor: not-allowed;
          }
        }

        .avatar {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.2rem;
          padding-left: .65rem;
          --imageSize: calc(var(--right-width) / 2 - 2rem);

          .image,
          .imageDefault {
            width: var(--imageSize);
            height: var(--imageSize);
            border-radius: 6px;
            border: 1px solid #c5c5d2;
            line-height: var(--imageSize);
            text-align: center;
            font-size: 1.8rem;
          }

          .imageDefault {
            line-height: var(--imageSize);
            text-align: center;
            color: #fff;
            background-color: #10a37f;
          }

          /deep/ .el-upload--picture-card {
            width: var(--imageSize);
            height: var(--imageSize);
            line-height: calc(var(--imageSize) + 1rem);
          }
        }
      }

      .card:first-child {
        padding: 1.5rem 0;
      }

      .card:last-child {
        border: 0;
      }

      .ps {
        margin-top: 1rem;
        font-size: 1.2rem;
        color: #969799;

        .link {
          color: #3978f5;
        }
      }

      .ps:first-child {
        margin-top: 1.5rem;
        align-items: flex-start;
      }
    }

    .preview {
      text-align: center;
      margin-bottom: 7rem;

      img {
        width: 18rem;
        margin: 1.5rem auto;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    font-family: "HW Nuckle", sans-serif;
    /* 添加其他全局样式 */
    /* background-color: #000; */
    overflow: hidden;
  }
}
</style>
